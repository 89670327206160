import {
  Appmetrica,
  UserProfile,
  ProfileAttribute
} from 'capacitor-appmetrica';

export class AnalyticsService {
  private appmetrica: Appmetrica = new Appmetrica();

  async initialization() {
    await this.appmetrica.activate('03254bf0-cee0-4d2c-b8c6-c513b9c6d26d', {
      logs: true
    });
  }

  //   async logEvent(name: string, params?: Object) {
  //     await this.appmetrica.logEvent(name, params);
  //   }

  async setUserProfileID(id: string) {
    return this.appmetrica.setUserProfileID(id);
  }

  async reportUserProfile(name: string) {
    const userProfile = new UserProfile();
    // ProfileAttribute.BirthDate().withBirthDate(new Date()),
    // ProfileAttribute.CustomString('born_in').withValueIfUndefined('Moscow')
    userProfile.applyFromArray([ProfileAttribute.Name().withValue(name)]);

    await this.appmetrica.reportUserProfile(userProfile);
  }

  async getDeviceID(): Promise<string> {
    return this.appmetrica.getDeviceID();
  }
}
