import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import * as ROUTES from '@/../vendors/appcomponents/src/router/routes';
import * as PATH from '@/../vendors/appcomponents/src/router/constants';
import DEF from '@/../vendors/appcomponents/src/router/default';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => {},
    beforeEnter(to: any, from: any): string | boolean {
      return ROUTES.routeMainVideos().path;
    }
  },
  {
    path: '/',
    component: () => import('@/layouts/LayoutHome.vue'),
    children: [
      ...DEF
      // ROUTES.routeEvents('Events'),
      // ROUTES.routeNotifications('Notifications'),
    ]
  }
  // ROUTES.routeSchoolPublic('Schools'),
  // ROUTES.routeAuthors('Authors'),

  // ROUTES.routePostByArticle('PostModerator', PATH.PATH_POST_MODERATOR),
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  next();
});

export default router;
